import { autoinject, observable } from 'aurelia-framework';
import { IDateRange } from 'components/charts/range-filter/range-filter';
import {
    ThreatTotalPerStatusForRange,
    StatsApiClient,
    TotalPerStatus
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';

@autoinject()
export class StatusTotalsWidget {
    private totals: Array<{ status: string, total: number }>;
    @observable() private dateRange: IDateRange;
    private currentRangeDays: number;
    private previousRangeDays: number;
    private loading: boolean = true;

    constructor(
        private statsApi: StatsApiClient,
        private state: StateApi
    ) {
    }

    private async dateRangeChanged(): Promise<void> {
        this.loading = true;

        this.updateCurrentRangeDays();
        this.updatePreviousRangeDays();

        await this.initialise();
        this.loading = false;
    }

    private async initialise(): Promise<void> {
        // Fetch the current and previous period threat stats
        let historicThreatStats = await this.getHistoricThreatStats();
        let totalsPerStatus = historicThreatStats[0].totalPerStatus;
        let previousTotalsPerStatus = historicThreatStats[1].totalPerStatus;

        // Map the retrieved totals to items that will be displayed using our own custom status names
        // Combine counts of each status (except for Ongoing status) regardless of handled state.
        const mapped: EnrichedTotalPerStatus[] = [];

        // totals per status does not always contain all statuses, so we need to add those previous totals to the mapped array
        previousTotalsPerStatus.forEach((previousTotalPerStatus: TotalPerStatus) => {
            const existing = mapped.find((enrichedTotalPerStatus: EnrichedTotalPerStatus) => enrichedTotalPerStatus.status === previousTotalPerStatus.status);
            if (existing && existing.status !== 'Ongoing') {
                existing.previousTotal += previousTotalPerStatus.count;
            } else {
                mapped.push({
                    status: previousTotalPerStatus.status,
                    total: 0,
                    handled: previousTotalPerStatus.handled,
                    previousTotal: previousTotalPerStatus.count
                });
            }
        });

        totalsPerStatus.forEach((totalPerStatus: TotalPerStatus) => {
            const existing = totalPerStatus.status === 'Ongoing'
                ? mapped.find((mappedTotal: EnrichedTotalPerStatus) =>
                    mappedTotal.status === totalPerStatus.status
                    && mappedTotal.handled === totalPerStatus.handled
                )
                : mapped.find((mappedTotal: EnrichedTotalPerStatus) => mappedTotal.status === totalPerStatus.status);
            // Only the Ongoing (active) status should show the handled count separately
            if (existing) {
                existing.total += totalPerStatus.count;
            } else {
                mapped.push({
                    status: this.capitalizeFirstLetter(totalPerStatus.status),
                    total: totalPerStatus.count as number,
                    handled: totalPerStatus.handled as boolean,
                    previousTotal: 0
                });
            }
        });

        mapped.sort(this.customSort);

        this.totals = mapped;
    }

    private customSort(a: EnrichedTotalPerStatus, b: EnrichedTotalPerStatus): number {
        const customOrder = ['Acknowledged', 'Pending', 'Ongoing', 'Closed', 'Rejected'];

        const indexA = customOrder.indexOf(a.status);
        const indexB = customOrder.indexOf(b.status);

        // If both elements are in the custom order, compare their indices and handled states
        if (indexA !== -1 && indexB !== -1) {
            // When statuses are the same, order by handled state, unhanded first
            if (a.status === b.status)
                return a.handled ? 1 : -1;

            return indexA - indexB;
        }

        // If only one of the elements is in the custom order, prioritize it
        if (indexA !== -1) {
            return -1;
        } else if (indexB !== -1) {
            return 1;
        }

        // If none of the elements are in the custom order, maintain their original order
        return 0;
    }

    private capitalizeFirstLetter([first, ...rest]: string): string {
        return `${first.toUpperCase()}${rest.join('')}`;
    }

    private async getHistoricThreatStats(): Promise<ThreatTotalPerStatusForRange[]> {
        const previousFrom = this.dateRange.previousFrom;
        // To get only for 1day we need to check
        const previousUntil = this.dateRange.name == '1d' ? this.dateRange.previousFrom : this.dateRange.from;

        try {
            return await this.statsApi.historicThreatStats(
                this.state.company(),
                this.dateRange.from.toDate(),
                this.dateRange.until.toDate(),
                previousFrom.toDate(),
                previousUntil.toDate()
            );
        } catch (error) {
            Toast.statsApiError();
            throw error;
        }
    }

    private updateCurrentRangeDays(): void {
        this.currentRangeDays = this.dateRange.until.diff(this.dateRange.from, 'days');
    }

    private updatePreviousRangeDays(): void {
        // Specific to a month range
        if (this.dateRange.name === '1m') {
            // Because each month has a different amount of days, we need to first subtract a month to calculate the
            // amount of days this range will span
            const previousMonthStart = this.dateRange.from.clone().subtract(1, 'month');

            // Save the amount of days between previousMonthStart and the regular dateRange's from date
            this.previousRangeDays = this.dateRange.from.diff(previousMonthStart, 'days');
        } else
            // For anything other than the 1m range (1w, 1d), we can just check for the amount of days
            this.previousRangeDays = this.dateRange.until.diff(this.dateRange.from, 'days');
    }
}

type EnrichedTotalPerStatus = {
    status: string,
    handled: boolean,
    total: number,
    previousTotal: number
};
