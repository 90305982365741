import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, containerless } from 'aurelia-framework';
import moment from 'moment';
import { EventKeys } from 'enums/event-keys';
import { LatestVesselRisk } from 'services/cyber-api';

@containerless()
export class FleetRiskIndicatorWidget {
    @bindable() private latestVesselRisk: LatestVesselRisk[];
    private loaded: moment.Moment = moment();
    private lastUpdateLabel: string = 'loading...';
    private riskLevelPercentages: any;

    constructor(private ea: EventAggregator) {
    }

    private async attached(): Promise<void> {
        /*
         * Not-so elegant way get the last updated to 'now' + update it automagically
         */

        // run once
        setTimeout(() => {
            this.lastUpdateLabel = moment(this.loaded).fromNow();
        }, 3000);

        // keep on running
        setInterval(() => {
            this.lastUpdateLabel = moment(this.loaded).fromNow();
        }, 5000);
    }

    private async refresh(): Promise<void> {
        this.ea.publish(EventKeys.onRefresh);
    }
}
