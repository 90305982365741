import { autoinject, containerless } from 'aurelia-framework';
import { EdrAgentResult, EdrApiClient, EdrHostContainmentState, } from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject()
@containerless()
export class MachineIsolationWidget {
    private containedMachines: EdrAgentResult[];

    constructor(
        private state: StateApi,
        private edrApi: EdrApiClient
    ) {
    }

    private async attached(): Promise<void> {
        await this.fetchContainedMachines();
    }

    private async fetchContainedMachines(): Promise<void> {
        let data = await this.edrApi.hosts(this.state.company(), undefined, 1000, 0, 'LastPollTimestamp:desc', undefined, undefined, true);
        this.containedMachines = data.items;
    }
}
