import { autoinject, containerless } from 'aurelia-framework';
import { ActionTarget, DetectionTypes, StatsApiClient } from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject()
@containerless()
export class ThreatTargetsWidget {
    private targetStats: ActionTarget[];
    private DetectionTypes: typeof DetectionTypes = DetectionTypes;

    constructor(
        private state: StateApi,
        private statsApi: StatsApiClient
    ) {
    }

    private async attached(): Promise<void> {
        this.statsApi.actionTargets(this.state.company(), undefined, 5)
            .then((targetStats) => {
                // Sort by count descending in case sorting isn't right
                targetStats.sort((a, b) => b.count - a.count);

                this.targetStats = targetStats;
            });
    }

    private getTargetSearchString(type: DetectionTypes, target: string): string {
        switch (type) {
            case DetectionTypes.Domain:
                return `domain:${target}`;
            case DetectionTypes.IpAddress:
                return `ip:${target}`;
            case DetectionTypes.MacAddress:
                return `mac:${target}`;
            case DetectionTypes.Unspecified:
            default:
                return target;
        }
    }
}
