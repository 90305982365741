import { autoinject, containerless } from 'aurelia-framework';
import { SortableThreatFields, ThreatSummary, ThreatsApiClient } from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject()
@containerless()
export class RecentUrgentThreatsWidget {
    private threats: ThreatSummary[] = undefined;

    constructor(
        private threatsApi: ThreatsApiClient,
        private stateApi: StateApi
    ) { }

    private bind(): void {
        // Fetch 5 latest active threats, sorted by urgency desc.
        const status = 'Ongoing';
        this.threatsApi.getAllSummaries(this.stateApi.company(), undefined, 5, 0, SortableThreatFields.Urgency, 'desc', undefined, undefined, undefined, undefined, status)
            .then((pagedThreats) => this.threats = pagedThreats.items);

    }

}
