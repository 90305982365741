import { autoinject } from 'aurelia-framework';
import { LatestVesselRisk, StatsApiClient } from '../../services/cyber-api';
import { Toast } from '../../utilities/toast';
import { StateApi } from '../../services/state-api';

@autoinject
export class Dashboard {
    private latestVesselRisk: LatestVesselRisk[];

    constructor(
        private stateApi: StateApi,
        private statsApi: StatsApiClient
    ) {
    }

    private async attached(): Promise<void> {
        this.getLatestVesselRisk()
            .then((latestVesselRisk) => this.latestVesselRisk = latestVesselRisk);
    }

    private async getLatestVesselRisk(): Promise<LatestVesselRisk[]> {
        try {
            return await this.statsApi.latestVesselsRisk(this.stateApi.company());
        } catch (error) {
            Toast.statsApiError();
            throw error;
        }
    }
}
