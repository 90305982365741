import { autoinject, bindable, containerless } from 'aurelia-framework';
import {
    ILatestVesselRisk,
    LatestVesselRisk
} from 'services/cyber-api';
import { Toast } from 'utilities/toast';

@containerless()
@autoinject()
export class TopVesselsWidget {
    @bindable() private latestVesselRisk: LatestVesselRisk[];
    private vesselCount: number;
    private highestRiskVessels: ILatestVesselRisk[];

    private async latestVesselRiskChanged(): Promise<void> {
        this.vesselCount = this.latestVesselRisk.length;

        this.highestRiskVessels = this.getHighestRiskVessels(this.latestVesselRisk);
    }

    private getHighestRiskVessels(latestVesselRisk: LatestVesselRisk[]): LatestVesselRisk[] {
        try {
            // Only take out the top 5 risk stats to show
            latestVesselRisk.sort((a, b) => b.currentRisk - a.currentRisk);
            return latestVesselRisk.slice(0, 5);
        } catch (error) {
            Toast.statsApiError();
            throw error;
        }
    }
}
